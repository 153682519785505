import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress } from '@mui/material'; // Import the CircularProgress component
import '@kitware/vtk.js/favicon';
import '@kitware/vtk.js/Rendering/Profiles/Geometry';
import '@kitware/vtk.js/Rendering/Profiles/Glyph';

import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkFullScreenRenderWindow from '@kitware/vtk.js/Rendering/Misc/FullScreenRenderWindow';
import vtkConeSource from '@kitware/vtk.js/Filters/Sources/ConeSource';
import vtkGlyph3DMapper from '@kitware/vtk.js/Rendering/Core/Glyph3DMapper';
import vtkAppendPolyData from '@kitware/vtk.js/Filters/General/AppendPolyData';
import vtkPolyData from '@kitware/vtk.js/Common/DataModel/PolyData';
import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray';
import vtkSTLReader from '@kitware/vtk.js/IO/Geometry/STLReader';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkColorTransferFunction from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction';
import vtkPlaneSource from '@kitware/vtk.js/Filters/Sources/PlaneSource';
import { ColorMode, ScalarMode } from '@kitware/vtk.js/Rendering/Core/Mapper/Constants';
import vtkScalarBarActor from '@kitware/vtk.js/Rendering/Core/ScalarBarActor';
import useFetchPositions from '../hooks/useFetchPositions';
import useFetchDirections from '../hooks/useFetchDirections';
import ControlPanel from './ControlPanel';
import useFetchAndCacheData from '../hooks/useFetchAndCacheData';

function WindVisualizer({ windSpeedRange = [0, 6] }) {
    
    const [height, setHeight] = useState(20);
    const [time, setTime] = useState(1);
    const [positions, setPositions] = useState([]);
    const [cachedData, setCachedData] = useState({});
    const [directions, setDirections] = useState({});
    const [firstRendered, setFirstRendered] = useState(false);
    const [loading, setLoading] = useState(true);

    // Add performance logging
    const perfLogs = useRef({
        start: performance.now(),
        lastLog: performance.now()
    });

    const logPerformance = (label) => {
        const now = performance.now();
        const sinceLast = now - perfLogs.current.lastLog;
        const sinceStart = now - perfLogs.current.start;
        console.log(`[Performance] ${label}:`);
        console.log(`  - Time since last log: ${sinceLast.toFixed(2)}ms`);
        console.log(`  - Time since start: ${sinceStart.toFixed(2)}ms`);
        perfLogs.current.lastLog = now;
    };

    // Add to loading effect
    useEffect(()=>{
        if (loading && 
            positions.length > 0 && 
            directions[time] && 
            Object.keys(directions[time]).length > 0 && 
            firstRendered) {
            logPerformance('All data loaded');
            console.log("done loading");
            setTimeout(()=>{
                setLoading(false);
            }, 200);
        }
    }, [positions, directions, time, firstRendered, loading]);

    useEffect(() => {
        if (cachedData[height] && cachedData[height][time]) {
            setDirections(prevDirections => ({
                ...prevDirections,
                [time]: cachedData[height][time]
            }));
        }
    }, [height, time, cachedData]);

    const rendererRef = useRef(null);
    const rendererWindowRef = useRef(null);
    const loadedSTL = useRef(false);

    useFetchPositions(setPositions);
    useFetchAndCacheData(cachedData, setCachedData);
    useFetchDirections(height, setDirections, cachedData);

    function resetView() {
        rendererRef.current.getActiveCamera().set({ position: [0, 0, 10000], viewUp: [0, 1, 0], focalPoint: [0, 0, 0] });
        rendererRef.current.resetCamera();
        rendererWindowRef.current.render();
    }

    useEffect(() => {
        const fullScreenRenderer = vtkFullScreenRenderWindow.newInstance({
            background: [0.2, 0.3, 0.4],
        });
        const renderer = fullScreenRenderer.getRenderer();
        const renderWindow = fullScreenRenderer.getRenderWindow();

        rendererRef.current = renderer;
        rendererWindowRef.current = renderWindow;

    }, []);

    useEffect(() => {
        if (loadedSTL.current || !rendererRef.current) return;
        logPerformance('Starting STL load');

        // Create actors and mappers once outside the loadSTL function
        const stlMappers = new Map();
        const stlActors = new Map();

        const loadSTL = async (url, position) => {
            const startTime = performance.now();
            const stlReader = vtkSTLReader.newInstance();
            
            // Create mapper and actor only once per URL
            if (!stlMappers.has(url)) {
                const mapper = vtkMapper.newInstance();
                const actor = vtkActor.newInstance();
                mapper.setScalarVisibility(false);
                actor.setMapper(mapper);
                actor.setPosition(...position);
                
                stlMappers.set(url, mapper);
                stlActors.set(url, actor);
            }

            try {
                await stlReader.setUrl(url);
                const mapper = stlMappers.get(url);
                mapper.setInputConnection(stlReader.getOutputPort());
                rendererRef.current.addActor(stlActors.get(url));
                
                const totalTime = performance.now() - startTime;
                console.log(`Total STL processing time for ${url}: ${totalTime.toFixed(2)}ms`);
                logPerformance(`Loaded STL: ${url}`);
            } catch (error) {
                console.error(`Error loading ${url}:`, error);
            }
        };

        // Load STLs concurrently
        Promise.all([
            loadSTL(`city/sample.stl`, [0, 0, 5]),
            loadSTL(`city/terrain.stl`, [0, 0, -1]),
            loadSTL(`city/buildings1.stl`, [0, 0, 0]),
            loadSTL(`city/buildings2.stl`, [0, 0, 0]),
            loadSTL(`city/buildings3.stl`, [0, 0, 0])
        ]).then(() => {
            loadedSTL.current = true;
            rendererWindowRef.current.render();
        });

    }, []);


    useEffect(() => {
        if (!positions || !directions[time] || positions.length === 0) {
            if(positions && positions.length > 0){
                logPerformance('loaded Positions data');
            }
            if(directions[time]){
                logPerformance('loaded Directions data');
            }
            return;
        }
        logPerformance('Starting visualization update');

        // Data flattening
        logPerformance('Starting data flattening');
        const flatPositions = positions.flat();
        const flatDirections = Array.isArray(directions[time]) ? 
            directions[time].flat() : 
            Object.values(directions[time]).flat();
        logPerformance('Finished data flattening');

        const renderer = rendererRef.current;
        const renderWindow = rendererWindowRef.current;

        // VTK object creation
        logPerformance('Starting VTK object creation');
        const polyData = vtkPolyData.newInstance();
        const appendFilter = vtkAppendPolyData.newInstance();
        logPerformance('Finished VTK object creation');

        // Points processing
        logPerformance('Starting points processing');
        if (flatPositions.length % 3 === 0) {
            const points = new Float32Array(flatPositions.map((value, index) => 
                index % 3 === 2 ? height : value));
            polyData.getPoints().setData(points, 3);
        }
        logPerformance('Finished points processing');

        // Directions processing
        logPerformance('Starting directions processing');
        if (flatDirections.length % 3 === 0) {
            const orientationArray = vtkDataArray.newInstance({
                numberOfComponents: 3,
                values: flatDirections,
                name: 'flatDirections',
            });
            polyData.getPointData().setVectors(orientationArray);

            // Magnitude calculations
            logPerformance('Starting magnitude calculations');
            const magnitudes = new Float32Array(flatDirections.length / 3);
            for (let i = 0; i < flatDirections.length; i += 3) {
                const vx = flatDirections[i];
                const vy = flatDirections[i + 1];
                const vz = flatDirections[i + 2];
                magnitudes[i / 3] = Math.sqrt(vx * vx + vy * vy + vz * vz);
            }
            logPerformance('Finished magnitude calculations');

            const magnitudeArray = vtkDataArray.newInstance({
                numberOfComponents: 1,
                values: magnitudes,
                name: 'magnitude',
            });
            polyData.getPointData().addArray(magnitudeArray);
            polyData.getPointData().getArrayByName('magnitude').setRange(windSpeedRange[0], windSpeedRange[1]);
            polyData.getPointData().setActiveScalars('magnitude');
        }
        logPerformance('Finished directions processing');

        // Mapper and actor setup
        logPerformance('Starting mapper setup');
        appendFilter.setInputData(polyData);

        const mapper = vtkGlyph3DMapper.newInstance();
        const actor = vtkActor.newInstance();

        const coneSource = vtkConeSource.newInstance();
        coneSource.setResolution(12);
        coneSource.setHeight(3);
        coneSource.setRadius(0.2);

        mapper.setInputConnection(appendFilter.getOutputPort());
        mapper.setSourceConnection(coneSource.getOutputPort());
        mapper.setScaleFactor(5);
        mapper.setScaleModeToScaleByConstant();
        mapper.setOrientationModeToDirection();
        mapper.setOrientationArray('flatDirections');
        logPerformance('Finished mapper setup');

        // Color setup
        logPerformance('Starting color setup');
        const orientationArray = vtkDataArray.newInstance({
            numberOfComponents: 3,
            values: flatDirections.map((value, index) => (index % 3 === 2 ? 0 : value)),
            name: 'flatDirections',
        });
        polyData.getPointData().setVectors(orientationArray);

        const colorTransferFunction = vtkColorTransferFunction.newInstance();
        colorTransferFunction.addRGBPoint(windSpeedRange[0], 0.0, 0.0, 1.0);
        colorTransferFunction.addRGBPoint(windSpeedRange[1] / 2, 0.0, 1.0, 0.0);
        colorTransferFunction.addRGBPoint(windSpeedRange[1], 1.0, 0.0, 0.0);
        mapper.setLookupTable(colorTransferFunction);
        mapper.setScalarVisibility(false);
        logPerformance('Finished color setup');

        // Scalar plane setup
        logPerformance('Starting scalar plane setup');
        actor.setMapper(mapper);
        renderer.addActor(actor);
        let planeActor = null;
        let scalarBarActor = null;

        const addScalarPlane = () => {
            const point1 = [flatPositions[0], flatPositions[1], height];
            const point2 = [flatPositions[flatPositions.length - 3], flatPositions[flatPositions.length - 2], height];

            const resolutionScale = 1;
            const planeSource = vtkPlaneSource.newInstance({
                xResolution: 320 * resolutionScale,
                yResolution: 320 * resolutionScale,
                origin: point1,
                point1: [point2[0], point1[1], height],
                point2: [point1[0], point2[1], height],
            });

            const windSpeeds = polyData.getPointData().getArrayByName('magnitude').getData();

            const vtkWindDataArray = vtkDataArray.newInstance({
                name: 'magnitude',
                values: windSpeeds,
                numberOfComponents: 1,
            });

            planeSource.getOutputData().getPointData().addArray(vtkWindDataArray, 1);
            planeSource.getOutputData().getPointData().setActiveScalars('magnitude');

            planeSource.getOutputData().getCellData().addArray(vtkWindDataArray, 1);
            planeSource.getOutputData().getCellData().setActiveScalars('magnitude');

            const planeMapper = vtkMapper.newInstance();
            planeMapper.setInputConnection(planeSource.getOutputPort());

            planeMapper.set({
                interpolateScalarsBeforeMapping: true,
                colorMode: ColorMode.DEFAULT,
                scalarMode: ScalarMode.DEFAULT,
                useLookupTableScalarRange: true,
                scalarVisibility: true,
            });

            const lookupTable = vtkColorTransferFunction.newInstance();
            lookupTable.addRGBPoint(0, 0.0, 0.0, 1.0);
            lookupTable.addRGBPoint(1.5, 0.0, 1.0, 1.0);
            lookupTable.addRGBPoint(3, 0.0, 1.0, 0.0);
            lookupTable.addRGBPoint(4.5, 1.0, 1.0, 0.0);
            lookupTable.addRGBPoint(6, 1.0, 0.0, 0.0);

            lookupTable.setMappingRange(windSpeedRange[0], windSpeedRange[1]);
            planeMapper.setLookupTable(lookupTable);

            planeActor = vtkActor.newInstance();
            planeActor.getProperty().setPointSize(2);
            planeActor.getProperty().setInterpolationToFlat();
            planeActor.setMapper(planeMapper);

            renderer.addActor(planeActor);

            scalarBarActor = vtkScalarBarActor.newInstance();
            renderer.addActor(scalarBarActor);
            scalarBarActor.setScalarsToColors(lookupTable);
            scalarBarActor.setAxisLabel('Wind Speed [m/s]');
            return planeActor;
        };

        addScalarPlane();
        logPerformance('Finished scalar plane setup');

        // Final render
        logPerformance('Starting render');
        renderWindow.render();
        if (!firstRendered) {
            resetView();
            setFirstRendered(true);
            logPerformance('First render complete');
        }
        logPerformance('Finished render');

        return () => {
            renderer.removeActor(actor);
            renderer.removeActor(planeActor);
            renderer.removeActor(scalarBarActor);
            renderWindow.render();
        }
    }, [positions, directions, time, windSpeedRange, height]);

    return (
        <>
            {loading && (
                <div className="loading-overlay">
                    Loading... <span className="loading-icon"><CircularProgress className="fast-spinner" /></span>
                </div>
            )}
            <ControlPanel setHeight={setHeight} setTime={setTime} resetView={resetView} setLoading={setLoading} />
            <div id="vtk-container" />
        </>
    );
}

export default WindVisualizer;