import { useEffect } from "react";
import generatePositionsGrid from "../utils/positionGenerator";

/**
 * This is a hook that fetches positions from the server
 * @param {function} setPositions
 */
function useFetchPositions(setPositions) {
    useEffect(() => {
        console.log("Generating positions grid...");
        
        // Generate positions using the grid generator
        const positions = generatePositionsGrid();
        
        console.log("Generated positions:", positions.length);
        console.log("First position sample:", positions[0]);
        
        const xCoords = positions.map(pos => pos[0]);
        const yCoords = positions.map(pos => pos[1]);
        
        console.log("Position Boundaries:", {
            x: {
                min: Math.min(...xCoords),
                max: Math.max(...xCoords)
            },
            y: {
                min: Math.min(...yCoords),
                max: Math.max(...yCoords)
            },
            totalPoints: positions.length,
            width: Math.sqrt(positions.length),
            height: Math.sqrt(positions.length)
        });
        
        setPositions(positions);
    }, []);
}

export default useFetchPositions;