function generatePositionsGrid(config = {
  startX: -2500,
  startY: -2500,
  gridWidth: 321,
  gridHeight: 321,
  spacing: 15.625
}) {
  const positions = [];
  
  console.log("Generating grid with settings:", {
    startPoint: [config.startX, config.startY],
    endPoint: [
      config.startX + (config.gridWidth - 1) * config.spacing,
      config.startY + (config.gridHeight - 1) * config.spacing
    ],
    totalPoints: config.gridWidth * config.gridHeight,
    dimensions: `${config.gridWidth}x${config.gridHeight}`
  });
  
  for (let i = 0; i < config.gridWidth; i++) {
    for (let j = 0; j < config.gridHeight; j++) {
      const x = config.startX + (i * config.spacing);
      const y = config.startY + (j * config.spacing);
      positions.push([x, y, 0]); // Z coordinate will be set by height later
    }
  }
  
  return positions;
}

export default generatePositionsGrid; 