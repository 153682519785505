import { useEffect } from "react";

/**
 * This is a hook that fetches directions from the server
 * @param {function} setDirections
 */
function useFetchAndCacheData(cachedData, setCachedData) {
    useEffect(() => {
        const fetchDataSequentially = async () => {
            let heightList = [20, 50, 90];
            for (let height of heightList) {
                if (cachedData[height] !== undefined) {
                    console.log(`Already Cached for height ${height}`);
                    continue;
                }
                console.log(`Loading data for height ${height}...`);
                
                // Create an object to store all forecast times for this height
                let heightData = {};
                
                // Fetch each forecast time separately
                for (let forecastTime = 0; forecastTime <= 5; forecastTime++) {
                    try {
                        let url;
                        if(process.env.NODE_ENV === 'development'){
                            url = `http://localhost:5000/data/${height}/${forecastTime}`;
                        }else{
                            url = `https://server.urbanwindhk.com/data/${height}/${forecastTime}`;
                        }
                        const response = await fetch(url);
                        if (!response.ok) {
                            throw new Error(`HTTP error! status: ${response.status}`);
                        }
                        const data = await response.json();
                        // Merge the new data into heightData
                        Object.assign(heightData, data);
                        // Update cached data immediately after receiving forecast time 0
                        if (forecastTime == 0 && height == 20) {
                            console.log('AAA Updating cached data for height 20');
                            setCachedData((previousCachedData) => ({
                                ...previousCachedData,
                                [height]: heightData
                            }));
                        }
                        console.log(`Received data for height ${height}, time ${forecastTime}`);
                    } catch (error) {
                        console.error(`Error fetching data for height ${height}, time ${forecastTime}:`, error);
                    }
                }
                
                if (Object.keys(heightData).length === 0) {
                    console.warn(`Empty data received for height ${height}`);
                    continue;
                }
                
                setCachedData((previousCachedData) => ({
                    ...previousCachedData,
                    [height]: heightData
                }));
            }
        };

        fetchDataSequentially();
    }, [cachedData, setCachedData]);
}

export default useFetchAndCacheData;